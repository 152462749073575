<template>
    <div class="work-order">
        <van-search v-model="listQuery.workTitle" @search="onSearch" placeholder="请输入任务名称" shape="round" input-align="center"  background="transparent"/>
        <van-tabs v-model="activeTab"  @click="clickTab">
            <!-- <van-tab title="农事列表" name="all">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                >
                    <div class="item" v-for="(item, index) in list" :key="index">
                        <div class="left">
                            <p><span>园区:</span><span>{{item.orgName}}</span></p>
                            <p><span>标题:</span><span>{{item.workTitle}}</span></p>
                            <p><span>内容:</span><span>{{item.workContent}}</span></p>
                        </div>
                        <div class="right">
                            <p>{{item.orderTime}}</p>
                            <p class="m-0">
                                <span>状态:</span>
                                <span :class="{'status': true,'accept':item.orderStatus == 0, 'complete': item.orderStatus == 1, 'review': item.orderStatus == 2}">
                                    {{item.orderStatus == 0 ? '待接取' : item.orderStatus == 1 ? '待完成'  : item.orderStatus == 2 ? '待审核' : '已完成'}}
                                </span>
                            </p>
                        <    <div v-if="isAdmin">
                                <van-button type="danger" class="btn-accept" v-if="item.orderStatus == 0" @click="deleteOrder(item.orderId)">删除</van-button>
                                <van-button type="info" class="btn-accept" v-if="item.orderStatus == 1" @click="schedulingOrder(item)">调度</van-button>
                                <van-button type="info" class="btn-accept" v-if="item.orderStatus == 2" @click="reviewOrder(item)">审核</van-button>
                                <van-button type="primary" disabled class="btn-accept" v-if="item.orderStatus == 3">已完成</van-button>
                            </div> 
                            <div>
                                <van-button type="info" class="btn-accept" v-if="item.orderStatus == 0" @click="acceptOrder(item)">接受</van-button>
                                <van-button type="danger" class="btn-accept" v-if="item.orderStatus == 1" @click="completeorder(item)">待完成</van-button>
                                <van-button type="info" disabled class="btn-accept" v-if="item.orderStatus == 2">待审核</van-button>
                                <van-button type="primary" disabled class="btn-accept" v-if="item.orderStatus == 3">已完成</van-button>
                            </div>
                        </div>
                    </div>
                </van-list>
            </van-tab> -->
            <van-tab :title="'待接取(' + waitAcceptNum+')'" name="waitAccept">
                <div class="item" v-for="(item, index) in list" :key="index">
                    <div class="top">
                        <div class="top-left">
                            <p>{{item.workTitle}}</p>
                            <p>{{item.orderTime}}</p>
                        </div>
                        <div class="top-right wait-accept">待接单</div>
                    </div>
                    <div class="bottom">
                        <div class="bottom-left">
                            <span>地块名称:</span>
                            <span>{{item.orgName}}</span>
                        </div>
                        <div class="bottom-right wait-accept" @click="acceptOrder(item)">接单</div>
                    </div>
                </div>
                <div class="no-data" v-if="isNoData">
                    <div>
                        <img src="../../assets/images/icon_nodata.png"/>
                    </div>
                    <div>
                        <span class="no-data-text">暂无数据</span>
                    </div>
                </div>
            </van-tab>
            <van-tab :title="'待完成('+waitCompleteNum+')'" name="waitComplete">
                <div class="item" v-for="(item, index) in list" :key="index">
                    <div class="top">
                        <div class="top-left">
                            <p>{{item.workTitle}}</p>
                            <p>{{item.orderTime}}</p>
                        </div>
                        <div class="top-right wait-complete">待完成</div>
                    </div>
                    <div class="bottom">
                        <div class="bottom-left">
                            <span>地块名称:</span>
                            <span>{{item.orgName}}</span>
                        </div>
                        <div class="bottom-right wait-complete" @click="completeorder(item)">去完成-></div>
                    </div>
                </div>
                <div class="no-data" v-if="isNoData">
                    <div>
                        <img src="../../assets/images/icon_nodata.png"/>
                    </div>
                    <div>
                        <span class="no-data-text">暂无数据</span>
                    </div>
                </div>
            </van-tab>
            <van-tab :title="'待审核('+waitCheckNum+')'" name="waitCheck">
                <div class="item" v-for="(item, index) in list" :key="index">
                   <div class="top">
                        <div class="top-left">
                            <p>{{item.workTitle}}</p>
                            <p>{{item.orderTime}}</p>
                        </div>
                        <div class="top-right wait-check">待审核</div>
                    </div>
                    <div class="bottom">
                        <div class="bottom-left">
                            <span>地块名称:</span>
                            <span>{{item.orgName}}</span>
                        </div>
                    </div>
                </div>
                <div class="no-data" v-if="isNoData">
                    <div>
                        <img src="../../assets/images/icon_nodata.png"/>
                    </div>
                    <div>
                        <span class="no-data-text">暂无数据</span>
                    </div>
                </div>
            </van-tab> 
            <van-tab :title="'历史记录('+historyRecordNum+')'" name="historyRecord">
                <!-- <div class="item" v-for="(item, index) in list" :key="index">
                    <div class="left">
                        <p><span>园区:</span><span>{{item.orgName}}</span></p>
                        <p><span>标题:</span><span>{{item.workTitle}}</span></p>
                        <p><span>内容:</span><span>{{item.workContent}}</span></p>
                    </div>
                    <div class="right">
                        <p>{{item.orderTime}}</p>
                        <div>
                            <van-button type="primary" disabled class="btn-accept" v-if="item.orderStatus == 3">已完成</van-button>
                        </div>
                    </div>
                </div> -->
                <div class="item" v-for="(item, index) in list" :key="index">
                   <div class="top">
                        <div class="top-left">
                            <p>{{item.workTitle}}</p>
                            <p>{{item.orderTime}}</p>
                            <p>
                                <span>地块名称:</span>
                                <span>{{item.orgName}}</span>
                            </p>
                        </div>
                        <div class="top-right wait-history">
                            
                        </div>
                    </div>
                </div>
                <div class="no-data" v-if="isNoData">
                    <div>
                        <img src="../../assets/images/icon_nodata.png"/>
                    </div>
                    <div>
                        <span class="no-data-text">暂无数据</span>
                    </div>
                </div>
            </van-tab>
        </van-tabs>
        <router-link :to="{path: '/addOrder'}" >
            <div class="btn-add">+</div>
        </router-link>
        <!-- <review-order v-model="reviewDialog" :editingContact="editingContact" :isReview="isReview" @submitData="passReview"></review-order> -->
    </div>
</template>

<script>
import {
    listPageOrder,
    addOrder,
    modifyOrder,
    deleteOrder,
    getCurrentUser
} from '@/api/workOrder';
import reviewOrder from './reviewOrder';
import {
    getUserType
} from '@/utils/cookie'
export default {
    components: {
        reviewOrder
    },
    data() {
        return {
            isNoData: false,
            activeTab: 'waitAccept',
            loading: false,
            finished: false,
            isAdmin: true,//判断是否是管理员
            total: 0,
            reviewDialog: false,//是否显示审核弹窗
            isReview: true,//是否新增审核详情
            list: [],
            btnText: '接单',
            listQuery: {
                contactName: null,
                pageIndex: 1,
                pageSize: 500,
                orderStatus: 0
            },
            editingContact: {
                workTitle: '',
                remark: '',
                urlArr: [],
                imgUrl1: '',
                imgUrl2: '',
                imgUrl3: '',
                imgUrl4: '',
                imgUrl5: ''
            },
            waitAcceptNum: 0,
            waitCompleteNum: 0,
            waitCheckNum: 0,
            historyRecordNum: 0,
        }
    },
    mounted() {
        this.getCurrentUser();
        this.listPageOrder();
    },
    methods: {
        /** 搜索 */
        onSearch() {
            this.listPageOrder();
        },
        /** 获取所有列表 */
        listPageOrder() {
            listPageOrder(this.listQuery).then(res => {
                if (res.code == 200) {
                    this.list = res.data || [];
                    this.total = res.count || 0;
                    this.isNoData = this.list.length > 0 ? false : true;
                    this.getTotal();
                }
            })
        },
        /** 获取所有状态数量 */
        getTotal() {
            let obj = {
                pageIndex: 1,
                pageSize: 500
            };
            let obj1 = Object.assign({}, obj);
            let obj2 = Object.assign({}, obj);
            let obj3 = Object.assign({}, obj);
            let obj4 = Object.assign({}, obj);
            obj1.orderStatus = 0;
            obj2.orderStatus = 1;
            obj3.orderStatus = 2;
            obj4.orderStatus = 3;
            listPageOrder(obj1).then(res => {
                if (res.code == 200) {
                    this.waitAcceptNum = res.count;
                }
            });
            listPageOrder(obj2).then(res => {
                if (res.code == 200) {
                    this.waitCompleteNum = res.count;
                }
            });
            listPageOrder(obj3).then(res => {
                if (res.code == 200) {
                    this.waitCheckNum = res.count;
                }
            });
            listPageOrder(obj4).then(res => {
                if (res.code == 200) {
                    this.historyRecordNum = res.count;
                }
            });
        },
        /** 删除工单 */
        deleteOrder(id) {
            this.$dialog.confirm({
                title: '提示',
                message: '确定删除改工单?',
            })
            .then(() => {
                deleteOrder({orderId: id}).then(res => {
                     if (res.code == 200) {
                        this.$notify({ type: 'success', message: '删除成功' });
                        this.listPageOrder();
                    }
                }) 
            })
        },
        /** 审核工单 */
        reviewOrder(obj) {
            this.editingContact = JSON.parse(JSON.stringify(obj));
            this.editingContact.urlArr = [];
            for (let i = 0; i < 5; i++) {
                let url = 'imgUrl' + (i + 1);
                this.editingContact.urlArr[i] = obj[url];
            }
            this.isReview = true;
            this.reviewDialog = true;
        },
        /** 接受工单 */
        acceptOrder(obj) {
            this.$dialog.confirm({
                title: '提示',
                message: '确定接取工单【'+obj.workTitle+'】?',
            })
            .then(() => {
                let param = JSON.parse(JSON.stringify(obj));
                param.orderStatus = 1;
                modifyOrder(param).then(res => {
                    if (res.code == 200) {
                        this.$notify({ type: 'success', message: '接取工单成功' });
                        this.listPageOrder();
                    }
                })
            })
        },
        /** 调度工单 */
        schedulingOrder(obj) {
            this.$dialog.confirm({
                title: '提示',
                message: '是否将工单【'+obj.workTitle+'】改为待接取状态?',
            })
            .then(() => {
                let param = JSON.parse(JSON.stringify(obj));
                param.orderStatus = 0;
                modifyOrder(param).then(res => {
                    if (res.code == 200) {
                        this.$notify({ type: 'success', message: '调度成功' });
                        this.listPageOrder();
                    }
                });
            })
        },
        /** 完成工单 */
        completeorder(obj) {
            this.editingContact = JSON.parse(JSON.stringify(obj));
            this.editingContact.urlArr = [];
            for (let i = 0; i < 5; i++) {
                let url = 'imgUrl' + (i + 1);
                this.editingContact.urlArr[i] = obj[url];
            }
            this.isReview = false;
            // this.reviewDialog = true;
            this.$router.push({name: 'reviewOrder', params: obj})
        },
        /** 获取用户信息 */
        getCurrentUser() {
            this.isAdmin = getUserType() == 1 ? true : false;
            // this.listQuery.orderStatus = this.isAdmin ? '' : 0;
            /* getCurrentUser().then(res => {
                if (res.code == 200) {
                    this.isAdmin = res.data.type == 1 ? true : false;
                    this.listQuery.orderStatus = this.isAdmin ? '' : 0;
                }
            }) */
        },
        /** 加载 */
        onLoad() {
            /* this.listQuery.pageIndex++;
            this.listPageOrder(); */
            this.loading = false;
        },
        /** 通过 */
        passReview(val, flag) {
            modifyOrder(val).then(res => {
                if (res.code == 200) {
                    if (flag ) {
                        this.$notify({ type: 'success', message: '审核成功' });
                    } else {
                        this.$notify({ type: 'success', message: '提交成功' });
                    }
                    this.listPageOrder();
                    this.reviewDialog = false;
                }
            });
        },
        /** 点击切换tab */
        clickTab(name, title) {
            const statusStr = {
                // all: this.isAdmin ? '' : 0,
                all: '',
                waitComplete: 1,
                waitCheck: 2,
                historyRecord: 3,
                waitAccept: 0
            };
            this.listQuery.orderStatus = statusStr[name];
            this.listPageOrder();
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.name == 'personal') {
                vm.activeTab = to.params.active;
                if (to.params.active == 'waitAccept') {
                    vm.listQuery.orderStatus = 0;
                } else if (to.params.active == 'waitComplete') {
                    vm.listQuery.orderStatus = 1;
                } else if (to.params.active == 'waitCheck') {
                    vm.listQuery.orderStatus = 2;
                } else if (to.params.active == 'historyRecord') {
                    vm.listQuery.orderStatus = 3;
                } 
            }
        });
    }
}
</script>

<style lang="scss" scoped>
    .work-order {
        max-height: 610px;
        overflow: auto;
        >.van-search{
            margin:  14px;
        }
        .item {
            /* display: flex;
            justify-content: space-between; */
            margin-bottom: 12px;
            padding: 12px;
            border-radius: 4px;
            background-color: #fff;
            .top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .top-left {
                    p:nth-of-type(1) {
                        font-size: 16px;
                        font-weight: 500;
                        color: #4F4F4F;
                    }
                    p:nth-of-type(2) {
                        font-size: 12px;
                        color: #979797;
                    }
                    p:nth-of-type(3) {
                        margin-top: 20px;
                        font-size: 14px;
                        color: #525252;
                        span:nth-of-type(1) {
                            margin-right: 8px;
                        }
                        span:nth-of-type(2) {
                            display: inline-block;
                            max-width: 150px;
                            line-height: 12px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
                .top-right {
                    width: 54px;
                    height: 16px;
                    text-align: center;
                    border-radius: 10px;
                    font-size: 12px;
                    &.wait-accept {
                        color: #438EFD;
                        background: #D0E5FF;
                        border: 1px solid #4499FF;
                    }
                    &.wait-complete {
                        color: #FFA144;
                        background: #FFE6CC;
                        border: 1px solid #FFA144;
                    }
                    &.wait-check {
                        color: #469663;
                        background: #C4F1D4;
                        border: 1px solid #5EC182;
                    }
                    &.wait-history {
                        width: 82px;
                        height: 78px;
                        background: url(../../assets/images/workOrder/complete.png);
                        background-size: contain;
                    }
                }
            }
            .bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 20px;
                .bottom-left {
                    font-size: 14px;
                    line-height: 20px;
                    color: #525252;
                    span:nth-of-type(1) {
                        display: inline-block;
                        max-width: 60px;
                        line-height: 14px;
                        margin-right: 8px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    span:nth-of-type(2) {
                        display: inline-block;
                        max-width: 150px;
                        line-height: 14px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
                .bottom-right {
                    font-size: 14px;
                    &.wait-accept {
                        color: #228EFF;
                    }
                    &.wait-complete {
                        color: #FFA144;
                    }
                }
            }
        }
        .btn-add {
            position: fixed;
            bottom: 70px;
            right: 10px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            font-size: 26px;
            color: #fff;
            text-align: center;
            line-height: 40px;
            background-color: #3498F6;
        }
    }
</style>
<style lang="scss">
    .work-order {
        .van-tabs__content {
            width: 347px;
            margin: 0 auto;
            padding: 14px;
        }
        .van-tabs__wrap {
            padding-top: 10px;
            background-color: #fff;
        }
        .van-tabs__nav--line {
            .van-tab {
                border: 0;
            }
            .van-tabs__line {
                display: none;
            }
            .van-tab__text--ellipsis {
                font-size: 14px;
                color: #979797;
            }
            .van-tab--active .van-tab__text--ellipsis {
                color: #228EFF;
                background-color: transparent;
            }
            .van-tab--active {
                border-bottom: 2px solid #228EFF;
            }
        }
    }
</style>